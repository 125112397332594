$white: #ffffff;
$shadowColor: #f7f7f7;
$green: #87ba3b;
$light: #f5f5f5;
$dark: #4b4b4b;
$lighter: #f9f9f9;
$grey: #848484;
$orange: #ff8901;
$lighterDark: #9f9f9f;
$lightGrey: #cacaca;
$lighterGreen: #f1ffd5;
$red: #fe3f43;
$lightBlue: #f1f9ff;

$xxl: 45px;
$xl: 40px;
$l: 35px;
$m: 30px;
$s: 20px;
$xs: 15px;
$xxxs: 5px;

$expiredStickersContentWidth: 290px;

body {
  margin: 0;
  color: $dark;
  font-family: 'Nunito', sans-serif;
}

.header {
  font-weight: bold;
  font-size: 16px;
}

.secondary-header {
  color: $grey;
  font-weight: 900;
}

.fullWidth {
  width: 100%;
}

.user-emails textarea {
  height: 190px !important;
}

textarea {
  resize: none !important;
}

.specialists-address textarea::placeholder {
  text-align: center;
  color: $lighterDark;
}

.centred {
  margin: 0 auto;
}

.centredText {
  text-align: center;
}

.noPadding {
  padding: 0px !important;
}

.noMargin {
  margin: 0px !important;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexColumnCentered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexContainerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexContainerColumn {
  display: flex;
  flex-direction: column;
}

.modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.modalHeader {
  height: 0;
  border: none;
}

.modalMsg {
  font-size: 14px;
  margin: 8px 8px 24px 8px;
  white-space: pre-line;
}

.show .dropdown-toggle::after {
  border-top: 0.3em solid;
  border-bottom: 0;
}

.dropdownToggle {
  width: 100%;
  background: white;
  color: $dark;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $lighterGreen !important;
  border: none;
  color: $green !important;
  padding-left: $xs;
  padding-right: $xs;
  box-shadow: 0px 0px 10px 1px $light;
}

.newLineText {
  white-space: pre-line;
}

.boldText {
  font-weight: bold;
}

.alert {
  border-radius: 12px;
}

.infoAlert {
  //background-color: $lightBlue;
  color: $dark;
  border: none;
}

.alert-dismissible {
  padding: 0;
}

#mainNav,
.localNav {
  height: 60px;
  background-color: $light;
  color: $dark;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1002;
  top: 0;
  padding: 0 $s;
}

.nav-header-text {
  font-size: 14.6667px;
  font-weight: bold;
  color: $grey;
}

.logo-icon {
  //padding-right: 16px;
  text-align: right;
}

.dropdown-item {
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  width: 80%;
}

.register-section-title {
  font-size: 10pt;
  padding: 16px 0px 8px 0px;
}

.fixed-padding-register {
  padding-top: 11vh;
  padding-bottom: 11vh;
}

//#mainNavWithShape{
//  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='384.239' height='62.646' viewBox='0 0 384.239 62.646'%3E%3Cdefs%3E%3Cfilter id='Лента_с_кръг_за_крушка' x='0' y='0' width='384.239' height='62.646' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='1' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='1.5' result='blur'/%3E%3CfeFlood flood-color='%23858585' flood-opacity='0.161'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(%23Лента_с_кръг_за_крушка)'%3E%3Cpath id='Лента_с_кръг_за_крушка-2' data-name='Лента с кръг за крушка' d='M475.239,46.292,214,45.96a.475.475,0,0,0-.375-.17,3.682,3.682,0,0,1-2.8-1.254,7.478,7.478,0,0,1-1.5-2.759,16.167,16.167,0,0,1-.722-4.013,29.643,29.643,0,1,0-59.285,0,11.393,11.393,0,0,1-.954,4.013,7.025,7.025,0,0,1-2.14,2.759,6.654,6.654,0,0,1-4.091,1.254h-.047a1.042,1.042,0,0,0-.411.078L100,45.815V-7.354l375.239.265V46.292Z' transform='translate(-95.5 10.85)' fill='%23f7f7f7'/%3E%3C/g%3E%3C/svg%3E%0A");  overflow:auto;
//  background-repeat: no-repeat;
//  background-position: center; /* Center the image */
//  height: 60px;
//  position: fixed;
//  left: 0;
//  right: 0;
//  top: 0;
//  padding: 0 $s;
//  z-index: 1;
//}

.primaryHeader {
  color: $green;
  margin-bottom: $l;
  margin-top: $l;
  font-weight: bolder;
  font-size: 18px;
  text-align: center;

  p {
    text-transform: uppercase;
    margin: 0;
  }
}

.uppercase {
  text-transform: uppercase;
}

#mainNavWithShape {
  height: 60px;
  background-color: $light;
  color: $dark;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 3;
  top: 0;
  overflow: auto;
  padding: 0 $s;
}

#mainNavWithShape::before {
  content: ' ';
  position: absolute;
  width: 65px;
  height: 65px;
  background: #fff;
  left: 12%;
  top: 50%;
  border-radius: 50%;
}

.alert-message {
  padding: 2rem 1.25rem 0.75rem 1.25rem;
  font-size: 14px;
}

.footerNavBar {
  height: 60px;
  background-color: $light;
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  z-index: 1001;
  color: $green;
  width: 100%;
  left: 0;
  right: 0;
}

.fix-padding {
  padding-top: 0px;
  padding-bottom: 60px;
}

.mainTextColor {
  color: $dark;
}

.mainGreenColor {
  color: $green;
}

.modal-content {
  border-radius: 12px;
}

.modal-dialog {
  width: 300px;

  .facebookButton {
    width: 100%;

    .react-share__ShareButton {
      background-color: $green !important;
      margin: 0 0 5vh 0 !important;

      svg {
      }
    }
  }
}

#errorModal .modal-content {
  border: 1px solid $red;
}

.errorModalHeader {
  font-size: 18pt;
  margin-bottom: 12px;
}

.close span {
  color: $lighterDark;
}

.dot {
  height: 8px;
  width: 8px;
  border: 1px solid $green;
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  margin: 8px;
}

.activeDot {
  background-color: $green !important;
}

.text-buttons {
  text-align: center;
  margin-top: $xs;
}

.text-btn {
  text-align: center;
  font-family: 'Nunito-Regular', serif;
  font-size: 14px;
  color: $green;
  margin-top: $xxxs;

  &:hover {
    color: $green;
  }
}

.danger {
  color: $red;

  &:hover {
    color: $red;
  }
}

.greenBtn {
  margin-bottom: 10px;
  border-radius: 25px;
  height: 35px;
  font-size: 14px;
  padding: 0px;
  text-align: center;
  justify-content: center;
  background-color: $green;
  color: $white;
  border: none;
}

.redBtn {
  margin-bottom: 10px;
  border-radius: 25px;
  height: 35px;
  font-size: 14px;
  padding: 0px;
  text-align: center;
  justify-content: center;
  background-color: $red;
  color: $white;
  border: none;
}

.greenBtn {
  &:hover,
  &:focus,
  &:active {
    outline: 0 !important;
    box-shadow: 0 0 0 0rem transparent !important;
    background-color: $green;
    color: $white;
    border: none;
    text-decoration: none;
  }
}

.greenBtn:not(:disabled):not(.disabled).active,
.greenBtn:not(:disabled):not(.disabled):active,
.show > .greenBtn.dropdown-toggle {
  outline: 0 !important;
  box-shadow: 0 0 0 0rem transparent !important;
  background-color: $green;
  border: none;
}

.greenBtnOutline {
  margin-bottom: 10px;
  border-radius: 25px;
  height: 35px;
  font-size: 14px;
  padding: 0px;
  text-align: center;
  justify-content: center;
  background-color: white;
  color: $green;
  border-color: $green;
}

.redBtnOutline {
  margin-bottom: 10px;
  border-radius: 25px;
  height: 35px;
  font-size: 14px;
  padding: 0px;
  text-align: center;
  justify-content: center;
  background-color: white;
  color: $red;
  border-color: $red;
}

.greyBtnOutline {
  margin-bottom: 10px;
  border-radius: 25px;
  height: 35px;
  font-size: 14px;
  padding: 0px;
  text-align: center;
  justify-content: center;
  background-color: white;
  color: $grey;
  border-color: $grey;
}

.secondary-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

a.greenBtnOutline {
  &:hover,
  &:focus,
  &:active {
    outline: 0 !important;
    box-shadow: 0 0 0 0rem transparent !important;
    background: white;
    color: $green;
    border-color: $green;
    text-decoration: none;
  }
}

.greenBtnOutline:not(:disabled):not(.disabled).active,
.greenBtnOutline:not(:disabled):not(.disabled):active,
.show > .greenBtnOutline.dropdown-toggle {
  outline: 0 !important;
  box-shadow: 0 0 0 0rem transparent !important;
  background: white;
  color: $green;
  border-color: $green;
}

a.redBtn {
  &:hover,
  &:focus,
  &:active {
    outline: 0 !important;
    box-shadow: 0 0 0 0rem transparent !important;
    background: $red;
    color: white;
    border-color: $red;
    text-decoration: none;
  }
}

.redBtn:not(:disabled):not(.disabled).active,
.redBtn:not(:disabled):not(.disabled):active,
.show > .redBtn.dropdown-toggle {
  outline: 0 !important;
  box-shadow: 0 0 0 0rem transparent !important;
  background: $red;
  color: white;
  border-color: $red;
}

.missionRow {
  background-color: #f2fddc;
  margin: 12px 16px;
  padding: 8px;
  border-radius: 8px;
}

.infoParagraph {
  font-size: 12px;
  padding: 12px;
  text-align: center;
  margin-bottom: 0px;
  border-radius: 8px;
}

.featuresRow {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.featuresRow p {
  font-size: 12px;
  margin: 0px;
  white-space: break-spaces;
}

.paymentRow {
  margin: 30px 0px;
  border: 1px solid #cacaca;
  border-radius: 8px;
}

.input-container,
.date-container {
  margin-top: $m;
}

.profile-form {
  .input-container,
  .date-container {
    margin-top: $xs;
  }

  .header {
    margin-bottom: 0;
    margin-top: $l;
  }
}

.change-password-field {
  margin-top: $s;
}

.input-container,
.react-datepicker-wrapper {
  width: 300px;
}

.input-container input,
.react-datepicker-wrapper input {
  border: none;
  background-color: #efefef;
}

.input-container input::-webkit-input-placeholder,
.react-datepicker-wrapper input::-webkit-input-placeholder {
  text-align: center;
  color: $lighterDark;
}

.text-danger {
  color: $orange !important;
  display: contents;
}

.radio-container {
  display: flex;
  margin-top: 30px;
}

.checkbox-container {
  display: flex;
  width: 300px;
  margin-top: 8px;
  font-size: 14px;
}

input#areTermsAccepted,
input#areGDPRAccepted,
input#isSubscriptionActive {
  width: 50px;
  height: 30px;
  margin-right: 12px;
}

.checkbox-label {
  width: 240px;
  margin-right: 12px;
  margin-top: 8px;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.birthInfo {
  font-size: 14px;
  padding: 18px 0px;
}

.birthInfoCol {
  padding: 0px 4px;
}

.radio-container {
  display: flex;
  justify-content: space-between;
  width: 76%;

  .radio {
    label {
      span {
        color: $dark;
      }

      display: flex;
    }

    .radio-button {
      width: 25px;
      height: 25px;
      border-radius: 15px;
      border: solid $lighterDark 1.5px;
      cursor: pointer;
      border-spacing: initial;
      border-collapse: separate;
      margin-right: 10px;
    }

    .checked {
      border-color: $green;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 5px 5px $lighterGreen;

      div {
        display: flex;
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background-color: $green;
      }
    }

    input {
      display: none;
    }
  }
}

.checkbox-container {
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    flex: 1;

    span {
      flex: 1;
      color: $dark;
    }

    div {
      margin-right: 20px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      border: solid $lighterDark 1.5px;
      border-radius: 5px;
    }
  }

  .checked {
    display: flex;
    background-color: $lighterGreen;
    align-items: center;
    justify-content: center;
    padding-bottom: 3px;

    &:after {
      content: '';
      width: 9px;
      height: 18px;
      border: solid $green;
      border-width: 0rem 0.2rem 0.24rem 0rem;
      transform: rotate(45deg);
    }
  }
}

.react-datepicker {
  button:hover {
    span {
      color: white;
    }
  }

  background-color: white;
  border: none !important;
  box-shadow: 0px 0px 10px 1px $lighterDark;

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: $lighterDark;
    border-radius: 50px;
  }

  .react-datepicker__day--keyboard-selected:hover {
    color: black;
  }

  .react-datepicker__day:hover {
    background-color: white;
    box-shadow: 0px 0px 1px 1px $lighterDark;
    border-radius: 50px;
  }

  .react-datepicker__navigation {
    overflow: inherit;
  }

  .react-datepicker__navigation-icon {
    width: 100%;
  }

  .react-datepicker__navigation-icon--previous::before {
    left: 10px;
  }

  .react-datepicker__navigation-icon--next::before {
    right: 10px;
    left: auto;
  }

  .react-datepicker__navigation-icon {
    font-size: inherit;
  }

  .react-datepicker__navigation-icon::before,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-width: 2px 2px 0 0;
    border-color: white;
    color: white;
  }

  .react-datepicker__header {
    border-bottom: none;
    background-color: $lighterDark;

    div {
      color: white;
    }
  }
}

.welcomeMsg {
  margin-top: 20px;
  marginbottom: 30px;
  fontsize: 20px;
}

a {
  color: $green;
}

.hamburger-menu {
  width: 100%;
  height: 100%;
  z-index: 4;
  position: fixed;
  background-color: white;
  right: 0;
  animation: show 0.5s;
  padding: 69px $l;
  overflow-y: scroll;

  .button {
    .content {
      margin: 0 $s;
      display: flex;
      flex: 1;
      justify-content: space-between;
    }

    .svg {
      transform: rotate(180deg);
    }
  }
}

.section-title {
  p {
    margin: 0 $s;
  }
}

.menu-items {
  width: 100%;

  .button {
    .content {
      margin: 0 $s;
      display: flex;
      flex: 1;
      justify-content: space-between;
    }

    .svg {
      transform: rotate(180deg);
    }
  }
}

.hamburger-menu-disabled {
  animation: hide 0.5s;
  right: 10000px;
  opacity: 0;
}

@keyframes show {
  0% {
    opacity: 0;
    right: 250px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

@-moz-keyframes show {
  0% {
    opacity: 0;
    right: 250px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

@-webkit-keyframes show {
  0% {
    opacity: 0;
    right: 250px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

@-o-keyframes show {
  0% {
    opacity: 0;
    right: 250px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

@-ms-keyframes show {
  0% {
    opacity: 0;
    right: 250px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: 250px;
  }
}

@-moz-keyframes hide {
  0% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: 250px;
  }
}

@-webkit-keyframes hide {
  0% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: 250px;
  }
}

@-o-keyframes hide {
  0% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: 250px;
  }
}

@-ms-keyframes hide {
  0% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: 250px;
  }
}

.notificationIcon {
  //position: absolute;
  position: fixed;
  top: 4%;
  left: 15.6%;
  z-index: 5;
}

//GOALS AND TAGS SCREEN

.journalHeader {
  margin: 22px 0px;
  flex-direction: column;
}

.goalsRow {
  width: 310px;
  height: 150px;
  margin-bottom: 28px;
  border-radius: 12px;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 0%), 0 4px 8px 0 rgb(0 0 0 / 12%);
}

.goalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 12px;

  span {
    font-size: 11px;
    font-weight: bold;
  }
}

.tagsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px;
}

.tag {
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 9px;
}

.tagTitle {
  margin-left: 8px;
}

.not-added-sticker-mount-number {
  padding: 0 5%;
  width: 100%;
  box-sizing: content-box;
}

.fix-padding-safari {
  width: 100%;
  box-sizing: content-box;
}

.stickerGroupContainer {
  display: flex;
  flex-wrap: wrap;
  width: 295px;
  border-radius: 12px;
  justify-content: space-between;
  padding: 1.5% 5%;
  box-sizing: content-box;

  svg {
    width: 100%;
    height: auto;
  }
}

.ph-m {
  padding: 0 5% !important;
}

.stickersContainer {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  border: 1px solid $lightGrey;
  background: white;
  border-radius: 9%;
  align-items: center;
  justify-content: center;
  margin: 8% 0;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  img {
    width: 100%;
    padding: 5%;
  }

  .camera {
    margin: 35%;
    top: 0;
    left: 0;
  }
}

.userText {
  border-radius: 0.25rem;
}

.userTextPreview-outer {
  padding: 0 5% 5% 5%;
}

.userTextPreview-inner {
  max-width: 100%;
  overflow: auto;
  height: 100%;
}

.userTextPreview {
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $lightGrey;
  background: white;
  padding: 0.25em;
  width: 100%;
}

.add-sticker {
  .stickersContainer {
    margin: 2% 0 4% 0;
  }
}

.uploader {
  opacity: 0;
  position: relative;
  width: 80%;
  z-index: 1;
  left: 0;
  top: 0;
}

.photo-container-holder {
  overflow: hidden;
}

.image-preview,
.journal-picture {
  object-fit: cover;
  position: absolute;
  width: inherit;
  padding: 0% !important;
  height: 100%;
}

.ph-5p {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.mr-5p {
  margin-right: 5% !important;
}

.form-control:disabled {
  background-color: transparent;
}

.childName {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
}

.noBorder {
  border: none;
}

.stickerInfoSection,
.stickerSection {
  border-radius: 12px;
  white-space: pre-line;
  margin-top: 3vh;
  font-size: 14px;
}

.secondary {
  &.danger,
  &.danger:active {
    background-color: white !important;
    border: 1px solid $red !important;
    color: $red !important;
  }
}

.stickerDescription {
  color: $dark;
  white-space: pre-line;
  padding: 4vh 2vh 0vh 2vh;
  font-size: 14px;
}

.stickerInfoSection,
.stickerSection,
.stickerInfoSection {
  background: $light;
}

.stickerInfoSection {
  padding: 3vh 0;
}

.stickerInfoSectionText {
  padding: 0 3vh;
  font-size: 13px;
  white-space: pre-line;
}

.stickerDatesSection {
  margin-top: 3vh;

  .text-danger {
    display: inline-block;
    right: 0;
    left: 0;
    text-align: center;
  }
}

.expiredStickersInfoBox {
  margin-bottom: 3vh;
  padding: 2vh 0;
}

.expiredStickersInfoText {
  padding: 0 3vh;
  font-size: 13px;
  white-space: pre-line;
}

.datePickerContainer {
  border-radius: 24px;
  padding: 10px 12px;
  margin-top: 1vh;
  width: 110px;
  height: 42px;
}

.expectedEndDate {
  border: 1.5px solid #cacaca;
  border-radius: 24px;
  font-size: 13px;
  padding: 10px 12px;
  margin-top: 1vh;
  width: 110px;
  height: 42px;
}

.stickerDatesText {
  padding: 1vh 0vh;
  font-size: 13px;
  color: black;
}

input.invisibleContainer {
  background: transparent;
  color: transparent;
  outline: none;
}

.visibleContainer {
  width: 110px;
}

.visibleContainer {
  .react-datepicker-wrapper {
    width: 110px;
  }
}

.visibleContainer input {
  display: block;
  outline: none;
  background: transparent;
  color: black;
  width: 110px;
  caret-color: transparent;
  font-size: 13px;
  text-align: center;
}

.visibleContainer input::placeholder {
  color: white !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
}

.noContent {
  text-align: center;
  font-weight: bold;
  margin: 0 $xl;
}

.noContent {
  margin: 44px 0 0 0;
  color: $green;
}

.noContent.safe-header-padding {
  margin: 9px 0 0 0;
}

.noContent.useful-info-item {
  margin: 9px 0 0 0;
}

.visibleContainer,
.react-datepicker-wrapper,
.react-datepicker__input-container :focus {
  box-shadow: none !important;
  background: transparent;
}

.addToJournalBtn {
  border: none;
  text-transform: uppercase;
  border-radius: 24px;
  font-size: 13px;
  padding: 10px 12px;
  color: white;
  margin: 5vh 0vh;
  color: white;
  background: #c9c9c9;
  font-weight: bold;
}

.facebookButton {
  .react-share__ShareButton {
    width: 100%;
    border-radius: 24px !important;
    font-size: 13px !important;
    padding: 10px 12px !important;
    margin: 2vh 0vh !important;
    font-weight: bold !important;
    text-transform: uppercase;

    svg {
      margin: 0 0 0 $xxxs;
    }
  }
}

.month {
  margin-top: $l;
}

.journalItem.noPicture {
  display: flex;
  width: 50%;
  margin-left: auto;
  margin-right: auto;

  .stickersContainer {
    margin: 10%;
  }

  .userTextPreview-outer {
    padding: 0 10% 10% 10%;
  }

  .icon {
    width: 5em;
    height: 5em;
    top: 0%;
    left: 100%;
    transform: translate(-100%, -50%);

    svg {
      margin: 0 0 0 5%;
      width: 60%;
    }
  }

  .content {
    border-radius: 12px 0 12px 12px;
    width: 100%;
  }

  .date {
    left: 10%;
  }

  .menuDots {
    left: 12%;
  }

  .content .itemMenu {
    left: 10%;
    top: 5%;
  }
}

.limited-width {
  max-width: 500px;
}

.limited-width-journal {
  max-width: 180mm;
}

.dropdown {
  //z-index: 2;
  width: 100%;

  .items {
    top: -25px;
    position: absolute;
    margin-left: 16px;
    width: 83%;

    .item {
      background-color: $white;
    }
  }
}

.dropdown-menu {
  padding: 0;
  width: 100%;
  transform: translate3d(0px, 0px, 0px);
  border: none;
  box-shadow: 0px 0px 10px -1px $lighterDark;
  overflow-y: scroll;
  max-height: 50vh;
  z-index: 3;
}

.dropdownMenu {
  width: 80%;
  margin-left: 16px;
}

.expiredStickersFilter {
  width: $expiredStickersContentWidth;
}

.expiredStickersFilter {
  width: $expiredStickersContentWidth;
}

.menuDots {
  display: flex;
  height: 15px;

  div {
    width: 3px;
    height: 3px;
    border-radius: 50px;
    margin-left: 1px;
    margin-right: 1px;
  }
}

.itemMenu {
  position: absolute;
  background-color: $white;
  border-radius: 9px;
  z-index: 1;
  right: 5%;
  top: 5%;
  list-style-type: none;
  padding: 9px;
  box-shadow: 0px 0px 3px 0.5px $light;
  font-size: 12px;

  li {
    margin: 0px 0px 5px 5px;
  }
}

.journalItem {
  margin-top: 15%;
  justify-content: center;
  position: relative;
  font-size: 2.5vmin;

  .date {
    position: absolute;
    color: $lighterDark;
    top: -9%;
    left: 13%;
  }

  .icon {
    svg {
      display: inline-block;
      position: absolute;
      top: 5%;
      left: 15%;
      width: 70%;
    }

    width: 5em;
    height: 5em;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 100%;
  }

  .content {
    position: relative;
    border-radius: 12px;

    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 10px 1px $light;

    .stickersContainer {
      z-index: 1;
      width: 100%;
      height: auto;
    }
  }

  img {
    width: 100%;
    padding: 8%;
  }

  .menuDots {
    position: absolute;
    right: 7.5%;
    top: 5%;
  }

  .stickersContainer {
    margin: 5%;

    .journal-picture {
      border-radius: 12px;
      object-fit: cover;
      position: absolute;
    }
  }
}

.stickerDatesSection .form-control[readonly] {
  background-color: transparent;
}

.journal-items {
  width: 100%;
  z-index: 0;
}

.sticker-photo-preview {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: 60px;
}

.spinner-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  //background: rgba(0, 0, 0, 0.3);
  background: $white;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 2;
  transition: all ease 1s;
}

#mainNav.transparent {
  background-color: rgba(245, 245, 245, 0.3);
}

.expired-stickers {
  img {
    padding: 5%;
  }

  & > div:nth-child(even) {
  }

  .sticker {
    display: flex;
    width: 46%;
  }

  .yellow-sticker {
    width: 46%;

    svg {
      width: 118%;
    }
  }

  img {
    width: 100%;
    padding: 5%;
  }
}

.break-line-text {
  white-space: pre;
}

.description {
  margin-top: $s;
  margin-left: $xxl;
  margin-right: $xxl;
  text-align: center;
}

.mt-m {
  margin-top: 35px !important;
}

.mt-s {
  margin-top: 30px !important;
}

.light-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  //background-color: $lighter;
}

.rotated-arrow {
  transform: rotate(180deg);
}

.useful-info-item {
  background-color: $white;
  padding: $xs;
  border-radius: 12px;
  box-shadow: 0px 0px 10px -1px $lighterDark;

  img {
    width: 100%;
  }

  h5 {
    font-size: 18px;
    font-weight: bold;
    color: $green;
    padding: 15px 0;
  }

  p {
    padding-top: 15px;
  }

  a {
    margin-bottom: 15px;
    display: block;
  }
}

.useful-info-items .useful-info-item:not(:first-child) {
  margin-top: $m;
  background-color: transparent;
}

.breastfeeding {
  margin: $l 0;

  .secondary-header {
    margin: 0 0 36px 0;
  }

  .history {
    .date,
    .time {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .indicator {
      padding: 0 0 0 0;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .point {
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 2.5px;
        background-color: $green;
      }

      .line {
        width: 1px;
        height: 40px;
        background-color: $green;
      }
    }

    p {
      margin: 0;
      font-size: 14px;
      color: $grey;
    }

    div:last-of-type span:first-of-type {
      color: $green;
      font-weight: 900;
      font-size: 14px;
    }

    span {
      font-size: 14px;
      color: $grey;
    }

    .time {
      white-space: nowrap;
    }
    .date {
      justify-content: flex-end;
    }
  }
  .details {
    width: 100%;
    background-color: $lighterGreen;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    align-items: center;
  }
  .text {
    align-self: center;
    margin: 0 0 0 $s;
    p:nth-of-type(1) {
      color: $green;
    }
    span,
    p:nth-of-type(2) {
      color: $grey;
    }
    strong {
      font-weight: 900;
    }
  }
  .breastfeeding-buttons {
    display: flex;
    width: 100%;
    padding: 0 $s $s $s;
    justify-content: space-between;
  }
  .btn {
    max-width: 190px;
    margin: 0 auto;
  }
  .timer {
    margin: $m 0 0 0;
    color: $grey;
    font-size: 50px;
  }

  .history-item {
    width: 100%;
    display: flex;

    p {
      margin: 0;
      font-size: 14px;
    }
    div:last-of-type span:first-of-type {
      color: $green;
      font-weight: 900;
    }

    span {
      font-size: 14px;
    }

    .time {
      margin: 0 0 0 auto;
      width: 50%;
      white-space: nowrap;
    }

    .indicator {
      // padding: 0 0 0 10%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .point {
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 2.5px;
        background-color: $green;
      }

      .line {
        width: 1px;
        height: 100%;
        background-color: $green;
      }
    }
  }
}

.tracker-form {
  width: 100%;
}
/* ----------- Standard Mobile View ----------- */

/* Portrait */
@media only screen and (max-width: 767px) and (orientation: portrait) {
  .notificationIcon {
    top: 4.7%;
    left: 16.2%;
  }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .notificationIcon {
    top: 5.5%;
    left: 16.1%;
  }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .notificationIcon {
    top: 4.6%;
    left: 16.1%;
  }
}

/* ----------- iPhone 11 and iPhone XR ----------- */

/* Portrait */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
}

/* ----------- iPhone 12 Mini, iPhone 11 Pro, iPhone Xs, and iPhone X ----------- */

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .notificationIcon {
    top: 4.6%;
    left: 16.1%;
  }
}

/* ----------- iPhone 11 Pro Max and iPhone Xs Max ----------- */

/* Portrait */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .notificationIcon {
    top: 4%;
    left: 15.6%;
  }
}

/* ----------- iPhone 12 Mini ----------- */

/* Portrait */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
}

/* ----------- iPhone 12 and iPhone 12 Pro ----------- */

/* Portrait */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .notificationIcon {
    top: 3%;
    left: 15%;
  }
}

/* ----------- iPhone 12 Pro Max ----------- */

/* Portrait */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .notificationIcon {
    top: 4%;
    left: 15.7%;
  }
}

@media only screen and (min-width: 1280px) {
  .notificationIcon {
    top: 4.5%;
    left: 13%;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .notificationIcon {
    top: 2.3%;
    left: 13.5%;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .notificationIcon {
    top: 3.2%;
    left: 13.2%;
  }
}

.navigation-component-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 52px;
  position: fixed;
  z-index: 100;
}

.notification-bulb {
  cursor: pointer;
}

.back-button-container {
  background-color: $shadowColor;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  z-index: 3;
}

.back-button-container-desktop {
  width: 11%;
}
.back-button-container-mobile {
  width: 13%;
}

.notification-container {
  width: 18%;
  display: flex;
  justify-content: flex-start;
}

.bulb-container {
  width: 100%;
  z-index: 1;
}

.bulb-container-desktop {
  margin-left: -4%;
}

.bulb-container-mobile {
  margin-left: -75%;
}

.nav-grey-background {
  background-color: $shadowColor;
}

.header-container {
  background-color: $shadowColor;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.header-container-desktop {
  width: 40%;
}

.header-container-mobile {
  width: 48%;
}

.header-with-bulb-container-mobile {
  width: 33%;
}

.logo-icon-container {
  background-color: $shadowColor;
  width: 31%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 3;
}

#new-version-container {
  display: none;
  position: fixed;
  width: 100%;
  z-index: 112;
  background: rgb(255 212 0 / 85%);

  .card-body {
    text-align: center;
    z-index: 1;
    padding: 0px;
    margin-top: 0.75rem;

    #new-version-text {
      margin: 0;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 0.75rem;
    }

    #refresh-btn {
      font-family: 'Nunito';
      font-size: 12px;
      text-transform: uppercase;
      border-radius: 24px;
      background: white;
      border: 1px solid #fd9212;
      padding: 10px 12px;
      width: 128px;
      margin-bottom: 0.75rem;
    }
  }
}

#main-pwa-container {
  display: none;
  justify-content: center;
  align-items: center;

  #pwa-container {
    position: fixed;
    bottom: 32px;
    z-index: 1002;
    width: 90%;

    .card {
      border: 2px solid rgb(207 207 207);
      border-radius: 1rem;

      #close-a2hs-Btn {
        position: absolute;
        top: 6px;
        right: 12px;

        span {
          color: #7e7e7e;
        }
      }

      .pwa-header {
        display: flex;
        margin-top: 26px;
        justify-content: center;
        width: 90%;

        #pwa-banner-logo {
          width: 60px;
          height: 60px;
        }

        #pwa-header-text {
          font-size: 15px;
          text-align: center;
          font-weight: bold;
          margin-left: 22px;
          margin-bottom: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .card-body {
        flex-direction: column;
        padding: 0px;
        width: 75%;
        margin: 0 auto;

        #pwa-a2hs-text {
          text-align: center;
          margin-top: 18px;
          font-size: 14px;
        }

        #pwa-ios-text {
          display: none;
          text-align: center;
          margin-top: 18px;
          margin-bottom: 18px;
          font-size: 14px;

          #safari-tab-icon {
            width: 22px;
            margin-bottom: 6px;
          }

          #safari-add-icon {
            width: 22px;
            margin-bottom: 3px;
          }
        }

        .add-button {
          width: 100%;
          margin: 0px 0px 24px 0px;
        }
      }
    }
  }
}

a:hover {
  color: $green;
  text-decoration: none;
}

.top-banner-container {
  padding-top: 64px;
}
