@font-face {
  font-family: 'Nunito-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito-Regular'), local('Nunito-Regular'),
  url('../src/fonts/Nunito-Regular.woff') format('woff'),
  url('../src/fonts/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito-Regular'), local('Nunito-Regular'),
  url('../src/fonts/Nunito-Regular.woff') format('woff'),
  url('../src/fonts/Nunito-Regular.ttf') format('truetype');
}
